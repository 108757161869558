import axios from 'axios';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { addMonths } from 'date-fns';
import { options } from '../utils/cookie';
import { authSuccess, logout } from '../store/auth';
import { handleResetData } from '../store/company';
import {
    AUTH_TOKEN_COOKIE,
    REFRESH_TOKEN_COOKIE,
} from '../data/globalConstants';

const API_URL = process.env.API_URL;

const config = {
    baseURL: API_URL,
};
const api = axios.create(config);

export default api;

export const setAuthToken = (token) => {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const setNewToken = async (dispatch, req, res, refreshToken) => {
    try {
        const refreshTokenData = await getNewToken({
            refreshToken,
        });
        const { token: newToken, refreshToken: newRefreshToken } =
            refreshTokenData.data;

        setCookie(AUTH_TOKEN_COOKIE, newToken, { ...options, req, res });
        setCookie(REFRESH_TOKEN_COOKIE, newRefreshToken, {
            ...options,
            req,
            res,
            expires: addMonths(new Date(), 1),
        });

        await dispatch(authSuccess(newToken));
        setAuthToken(newToken);

        return { token: newToken, refreshToken: newRefreshToken };
    } catch (err) {
        deleteCookie(AUTH_TOKEN_COOKIE, { ...options, req, res });
        deleteCookie(REFRESH_TOKEN_COOKIE, { ...options, req, res });
        dispatch(logout());
        dispatch(handleResetData());
        return Promise.reject(err);
    }
};

export const setRefreshTokenInterceptor = (dispatch, req, res) => {
    // Clear existing response interceptors
    api.interceptors.response.handlers = [];

    api.interceptors.response.use(
        (response) => {
            return response;
        },
        async function (error) {
            if (!error.response) return Promise.reject(error);
            const originalRequest = error.config;
            const refreshToken = getCookie(REFRESH_TOKEN_COOKIE, { req, res });
            if (
                refreshToken &&
                error.response.status === 401 &&
                !originalRequest._retry
            ) {
                originalRequest._retry = true;

                try {
                    const { token } = await setNewToken(
                        dispatch,
                        req,
                        res,
                        refreshToken
                    );
                    originalRequest.headers[
                        'Authorization'
                    ] = `Bearer ${token}`;
                    return api(originalRequest);
                } catch (err) {
                    return Promise.reject(error);
                }
            }
            return Promise.reject(error);
        }
    );
};

export const login = async (data) => {
    return api.post('/auth/login', data);
};

export const getMe = async () => {
    return api.get('users/me');
};

export const getNewToken = async (data) => {
    return api.post('/auth/refresh', data);
};

export const deactivateUser = async (data) => {
    try {
        return await api.post('/auth/deactivate-user', data);
    } catch (err) {
        throw new Error(err);
    }
};

export const activateUser = async (data) => {
    try {
        return await api.post('/auth/activate-user', data);
    } catch (err) {
        throw new Error(err);
    }
};

export const promoteUser = async (data) => {
    try {
        return await api.post('/auth/promote-user', data);
    } catch (err) {
        throw new Error(err);
    }
};

export const registration = async (data) => {
    try {
        return api.post('/auth/register', data);
    } catch (err) {
        throw new Error(err);
    }
};

export const journalistRegistration = async (data) => {
    try {
        return api.post('/auth/register/journalist', data);
    } catch (err) {
        throw new Error(err);
    }
};

export const getReq = async (url) => {
    try {
        return await api.get(`${url}`);
    } catch (err) {
        throw new Error(err);
    }
};

export const paymentPostRequest = async (url, data) => {
    return await api.post(`${url}`, data);
};

export const postReq = async (url, data) => {
    try {
        return await api.post(`${url}`, data);
    } catch (err) {
        throw new Error(err);
    }
};

export const putReq = async (url, data) => {
    try {
        return await api.put(`${url}`, data);
    } catch (err) {
        throw new Error(err);
    }
};

export const patchReq = async (url, data) => {
    try {
        return await api.patch(`${url}`, data);
    } catch (err) {
        throw new Error(err);
    }
};

export const deleteReq = async (url) => {
    try {
        return await api.delete(`${url}`);
    } catch (err) {
        throw new Error(err);
    }
};

export const fileUpload = async (url, file, onProgress = null) => {
    try {
        const multipart = api.interceptors.request.use((config) => {
            config.headers['Content-Type'] = 'multipart/form-data';

            if (onProgress) {
                config.onUploadProgress = (progressEvent) => {
                    const totalLength = progressEvent.lengthComputable
                        ? progressEvent.total
                        : progressEvent.target.getResponseHeader(
                              'content-length'
                          ) ||
                          progressEvent.target.getResponseHeader(
                              'x-decompressed-content-length'
                          );
                    const prc = Math.round(
                        (progressEvent.loaded * 100) / totalLength
                    );
                    onProgress(prc);
                };
            }
            return config;
        });
        const res = await api.post(`${url}`, file);
        api.interceptors.request.eject(multipart);
        return res;
    } catch (err) {
        throw new Error(err);
    }
};

export const queryRequest = async (url, query) => {
    try {
        return await api.get(url, { params: query });
    } catch (err) {
        throw new Error(err);
    }
};

export const getSubscriptionProducts = async () => {
    try {
        return await api.get('/stripe/products');
    } catch (err) {
        throw new Error(err);
    }
};
